@import "../../libs/styles/web/main";

@mixin button-variation($background, $color, $hoverBackground: $background, $hoverColor: $color) {
  background-color: $background;
  color: $color;

  &:hover {
    @include no-touch-device-hover() {
      background-color: $hoverBackground;
      color: $hoverColor;
      cursor: pointer;
    }
  }

  &[disabled] {
    cursor: not-allowed;
    color: $grey;

    &:hover {
      @include no-touch-device-hover() {
        background-color: $background;
        color: $grey;
      }
    }

    svg {
      path {
        fill: $grey;
        stroke: $grey;
      }
    }
  }

  @content;
}

.btn {
  appearance: none !important;
  display: flex;
  align-items: center;
  width: auto;

  &.fullwidth {
    width: 100%;
  }

  &.alignCenter {
    justify-content: center;

    span {
      &:empty {
        display: none;
      }
    }
  }

  &.bold {
    font-weight: bold;
  }

  &.margin-left-auto {
    margin-left: auto;
  }

  &.space-bottom {
    bottom: $space-10;
  }

  &.readonly-label {
    .btn-label {
      font-style: italic !important;
    }
  }

  &:not([disabled]) {
    &.readonly-label {
      .btn-label {
        color: $dusty-primary !important;
      }
    }
  }

  .btn-label {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.show {
      display: flex;
      align-items: center;
    }

    &.hide {
      display: none;
    }

    .status-icon {
      position: relative;
      overflow: hidden;
      display: inline-block;
      width: $space-20;
      height: $space-20;
      border-radius: 50%;
      background-color: $white;
      margin-right: $space-10;

      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        width: rem(9.5px);
        height: $space-20;
        border: $base-border solid $white;
      }

      &:before {
        left: 0;
        border-right: 0;
        border-top-left-radius: rem(9.5px);
        border-bottom-left-radius: rem(9.5px);
      }

      &:after {
        right: 0;
        border-left: 0;
        border-top-right-radius: rem(9.5px);
        border-bottom-right-radius: rem(9.5px);
      }

      &.data-red {
        &:before {
          background-color: $danger;
        }
      }
      &.data-orange {
        &:before {
          background-color: $orange;
        }
      }
      &.data-green {
        &:before {
          background-color: $success;
        }
      }

      &.images-orange {
        &:after {
          background-color: $orange;
        }
      }
      &.images-green {
        &:after {
          background-color: $success;
        }
      }
    }
  }

  &.form-btn {
    padding: $space-10 $space-20;
  }

  &.no-icon {
    padding: $space-10 $space-20;
    text-align: center;
  }

  &.basic {
    @include button-variation($ice-primary, $black, $lighter-primary, $secondary) {
      svg {
        transition: transform 0.3s cubic-bezier(0.42, 0.63, 0.57, 1);
      }

      &.is-pressed {
        background-color: $lighter-primary;
        color: $secondary;

        svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  &.warning {
    @include button-variation($warning, $white, $warning-darker);
  }

  &.default {
    @include button-variation($ice-primary, $secondary) {
      &:active {
        background-color: $lighter-primary;
      }
    }
  }

  &.primary {
    @include button-variation($primary, $white, $primary-darker);
  }

  &.lighter-primary {
    @include button-variation($lighter-primary, $secondary, darken($lighter-primary, 10%), $black) {
      color: $black;
    };
  }

  &.secondary {
    @include button-variation($secondary, $white, $secondary-darker);

    &[disabled] {
      background-color: $light-grey !important;
      color: $white !important;
    }
  }

  &.danger {
    @include button-variation($danger, $white, $danger-darker);
  }

  &.ghost {
    @include button-variation(transparent, $secondary, $ice-primary) {
      &:active {
        background-color: $lighter-primary;
      }

      &.is-pressed {
        background-color: $lighter-primary;
        color: $secondary;

        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
  &.ghost-dark {
    @include button-variation(transparent, $secondary, $primary-darker) {
      &.is-pressed {
        background-color: $primary-darker;
      }
    }
  }

  &.ghost-select {
    padding: $space-10 !important;

    @include button-variation(transparent, $black, $lighter-primary) {
      &:active {
        background-color: $lighter-primary;
      }
    }
  }

  &.none {
    @include button-variation(transparent, $black);
  }

  &.transparent {
    @include button-variation(transparent, $white, $secondary-darker);

    &.form-btn-reset {
      @include button-variation(transparent, $secondary, rgba($black, 0.15), $white);
    }
  }

  &.no-icon {
    padding-left: $space-20;
    padding-right: $space-20;
  }

  &.icon-right {
    text-align: left;
    padding-left: $space-10;

    &.no-padding {
      padding-left: 0;
    }
  }

  &.icon-center {
    justify-content: center;
    padding-left: $space-10;
    padding-right: $space-10;

    &.no-padding {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.icon-left {
    flex-direction: row-reverse;
    text-align: left;
    padding-right: $space-10;

    &.no-padding {
      padding-right: 0;
    }
  }

  &.size-sm {
    &.medium-font-size {
      .btn-label {
        font-size: $font-size-main;
      }
    }

    .btn-label {
      font-size: $font-size-small;
    }

    svg {
      width: $space-30;
      height: $space-30;
    }
  }

  &.size-md {
    &.small-font-size {
      .btn-label {
        font-size: $font-size-main;
      }
    }

    .btn-label {
      font-size: rem(16px);
    }

    svg {
      width: $space-40;
      height: $space-40;
    }
  }

  &.size-lg {
    min-height: 40px;
    .btn-label {
      font-size: rem(14px);
    }

    svg {
      width: $space-40;
      height: $space-40;
      transition: transform 0.3s cubic-bezier(0.42, 0.63, 0.57, 1);
    }
  }

  &.size-xl {
    .btn-label {
      font-size: rem(16px);
    }

    svg {
      width: $space-60;
      height: $space-60;
    }
  }

  &.radius-small {
    border-radius: $border-radius;

    &.radius-none {
      border-radius: 0;
    }
  }

  &.radius-large {
    border-radius: $border-large-radius;
  }

  &:focus {
    outline: none;
  }
}

.button-group {
  .btn {
    border-radius: 0 !important;
  }
}
