@import "../../../libs/styles/web/main";

.search-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: $space-20;

  input[type=text] {
    position: absolute;
    padding-left: $space-10;
    padding-right: $space-40;
    height: 100%;
    width: 100%;

    &::placeholder {
      color: $black !important;
      font-style: normal;
    }
  }

  &.is-focused {
    .suggestions-container {
      opacity: 1;
      visibility: visible;
    }
  }

  .suggestions-container {
    position: absolute;
    top: calc(100% + #{$space-05});
    width: auto;
    min-width: 100%;
    z-index: 2;
    background-color: $white;
    filter: drop-shadow($base-box-shadow);
    border-radius: $border-radius;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    max-height: rem(275px);
    will-change: filter, visibility, opacity;

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }

    li {
      line-height: 1;

      &.empty-result {
        padding: $space-20 $space-85;
        text-align: center;
        font-style: italic;
        color: $grey;
        white-space: nowrap;
      }

      &.is-result {
        button {
          text-align: left;
          padding: $space-15 $space-10;
          background-color: $white;
          width: 100%;
          white-space: nowrap;

          &:hover {
            @include no-touch-device-hover() {
              background-color: $lighter-primary;
            }
          }
        }

        &.active {
          button {
            @include no-touch-device-hover() {
              background-color: $lighter-primary;
            }
          }
        }
      }
    }
  }

  button {
    position: relative;
    z-index: 1;
    background-color: transparent;

    .icon-container {
      width: $space-40;
      height: $space-40;

      svg {
        width: $space-20;
        height: $space-20;
      }
    }

    svg {
      width: $space-40;
      height: $space-40;
    }

    &:focus {
      outline: none;
    }
  }
}
