@import "../../libs/styles/web/main";

.app-menu {
  position: sticky;
  z-index: 10;
  top: 0;
  width: auto;
  transition: width .2s linear;
  height: calc(var(--vh, 1vh) * 100);
  background: linear-gradient(0deg, $primary 0%, $secondary 100%);
  box-shadow: inset calc(#{$space-05} * -1) 0 $space-35 0 rgba($black, 0.2);;

  .top-box {
    background-color: rgba($black, 0.15);
    border-bottom: 1px solid $light-primary;
    padding: $space-35 $space-20;
  }

  &.is-expanded {
    width: rem(400px);
  }

  .toggle-expand {
    position: absolute;
    z-index: 2;
    bottom: 30px;
    right: 0;
    transform: translateX(50%) rotate(180deg);
    background-color: $primary;
    border-radius: 50%;
    width: $space-30;
    height: $space-30;

    &:focus {
      outline: none;
    }

    &.is-expanded {
      transform: translateX(50%) rotate(0deg);
    }
  }
}
