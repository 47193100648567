@import "../../../libs/styles/web/main";

.select-list-title {
  text-transform: uppercase;
  font-weight: bolder;
  text-align: left;
}

.select-list-list {
  margin-bottom: 0;

  &:not(:last-of-type) {
    margin-bottom: $space-30;
  }
}
