@import "../../libs/styles/web/main";

.dataset-history {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $grey;
  margin: $space-30 0 $space-25 0;
  font-size: $font-size-small;
}
