@import "../../libs/styles/web/main";

[class^="imageEditorContainer"] {
  input[type="range"] {
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
    background-color: transparent
  }
  input[type="range"]:focus {
    outline: none;
  }
  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    background: $grey;
    border-radius: 25px;
    border: 0px solid $black;
  }
  input[type="range"]::-webkit-slider-thumb {
    border: 6px solid $white;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: $black;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -9px;
    animation-duration: 0.2s;
  }
  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: $light-primary;
  }
  input[type="range"]::-moz-range-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    background: $grey;
    border-radius: 25px;
    border: 0px solid $black;
  }
  input[type="range"]::-moz-range-thumb {
    border: 6px solid $white;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: $black;
    cursor: pointer;
    margin-top: -9px;
  }
  input[type="range"]::-ms-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    background: $grey;
    border-radius: 25px;
    border: 0px solid $black;
  }
  input[type="range"]::-ms-fill-lower {
    background: $grey;
    border: 0px solid $black;
    border-radius: 50px;
  }
  input[type="range"]::-ms-fill-upper {
    background: $grey;
    border: 0px solid $black;
    border-radius: 50px;
  }
  input[type="range"]::-ms-thumb {
    border: 6px solid $white;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: $black;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -9px;
  }
  input[type="range"]:focus::-ms-fill-lower {
    background: $grey;
  }
  input[type="range"]:focus::-ms-fill-upper {
    background: $grey;
  }

  body {
    padding: 10px;
  }
}
