@import "../../../../node_modules/react-datepicker/dist/react-datepicker.css";

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__header {
    padding-bottom: 8px;
}

.react-datepicker-popper[data-placement^="top"],
.react-datepicker-popper[data-placement^="bottom"] {
    width: 242px;

    .react-datepicker {
        width: 100%;
    }

    .react-datepicker__month-container,
    .react-datepicker__year--container {
        width: 100%;
    }

    .react-datepicker__year-wrapper {
        max-width: 100%;

        .react-datepicker__year .react-datepicker__year-text {
            width: 71px;
        }
    }

    .react-datepicker__triangle {
        left: 50% !important;
    }
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
    &:focus {
        outline: none;
    }
}
