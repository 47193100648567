@import "../../libs/styles/web/main";

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  background-color: rgba($secondary, 0.5);
  transition: opacity .15s linear, visibility .15s linear;

  &.show {
    opacity: 1;
    visibility: visible;
  }

  &.hide {
    opacity: 0;
    visibility: hidden;
  }
}
