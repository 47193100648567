@import "../../libs/styles/web/main";

.dropdown {
  position: relative;

  .dropdown-content-wrapper {
    position: absolute;
    top: calc(100% + #{$space-05});
    padding: $space-10;
    background-color: $white;
    box-shadow: $base-box-shadow;
    border-radius: $border-radius;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    width: auto;
    overflow: hidden;

    &.medium-padding {
      padding: $space-20;
    }

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }

    &.expand {
      opacity: 1;
      visibility: visible;
    }
  }
}
