@import "../../libs/styles/web/main";

.tab-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .controls-container > ul.tabs {
    @include inset-border($secondary, $base-border);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    border-radius: $border-radius;
    margin: 0;

    li.tab {
      position: relative;
      text-align: center;
      min-width: $tab-width;
      border-right: 1px solid $secondary;
      color: $secondary;
      cursor: pointer;
      font-size: $font-size-main;
      padding: rem(7px) 0;

      &:last-of-type {
        border-right: none;
      }

      /*
      * Since we need to fake the border with an inset box shadow,
      * to prevent additional calculating through the size to the height
      * the hover state must be fixed with an ::before.
       */
      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: transparent;
        top: 0;
        left: 0;
        z-index: -1;
      }

      &.active {
        background-color: $secondary;
        color: white;
      }

      &:not(.active) {
        &:hover {
          &:before {
            @include no-touch-device-hover() {
              background-color: $ice-primary;
            }
          }
        }
      }
    }
  }
}
