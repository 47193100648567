@import "libs/styles/web/main";
@import "libs/styles/web/base/reset";
@import "libs/styles/web/base/forms";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/grid";


:root {
  --size-05: 5px;
  --size-10: 10px;
  --size-15: 15px;
  --size-20: 20px;
  --size-25: 25px;
  --size-30: 30px;
  --size-35: 35px;
  --size-40: 40px;
  --size-45: 45px;
  --size-50: 50px;
  --size-55: 55px;
  --size-60: 60px;
  --size-65: 65px;
  --size-70: 70px;
  --size-75: 75px;
  --size-80: 80px;
  --size-85: 85px;
  --size-90: 90px;
  --size-95: 95px;
  --size-100: 100px;
  --form-label: 180px;
  --base-border: 2px;
  --base-font-size: 14px;
  --base-radius: var(--size-05);
  --base-drop-shadow: drop-shadow(0 var(--size-10) var(--size-50) rgba(0, 0, 0, 0.3));
  --base-drop-shadow-small: drop-shadow(0 var(--size-10) var(--size-20) rgba(0, 0, 0, 0.3));
  --primary:         #0c89bc;
  --primary-darker:  #096d96;
  --dusty-primary:   #81a4ca;
  --light-primary:   #87c0d8;
  --dustier-primary: #b3c8e0;
  --lighter-primary: #d9e4ef;
  --ice-primary:     #ecf1f7;
  --secondary:       #0b4a98;
  --secondary-darker: #073874;
  --ternary:         #144D93;
  --success:         #70b900;
  --warning:         #ff9a00;
  --warning-darker:  #CC7B00;
  --danger:          #ff2626;
  --danger-darker:   #E52222;
  --highlight:       #ee00ff;
  --grey:            #818181;
  --light-grey:      #b7b7b7;
  --lighter-grey:    #e5e5e5;
  --pale-grey:       #f2f2f2;
  --white-grey:      #f5f8fb;
  --darker-grey:     #0D0D0D;
  --black:           #000000;
  --white:           #ffffff;
  --font-size-title: 24px;
  --amplify-font-family:	'Arial', sans-serif;
  --amplify-text-xxs:	var(--base-font-size);
  --amplify-text-xs:	var(--base-font-size);
  --amplify-text-sm:	var(--base-font-size);
  --amplify-text-md:	var(--base-font-size);
  --amplify-text-lg:	var(--base-font-size);
  --amplify-text-xl:	var(--base-font-size);
  --amplify-text-xxl: var(--base-font-size);
  --amplify-primary-color: var(--secondary);
  --amplify-primary-contrast: var(--white);
  --amplify-primary-tint: var(--black);
  --amplify-primary-shade: var(--black);
  --amplify-secondary-color: var(--primary);
  --amplify-secondary-contrast: var(--white);
  --amplify-secondary-tint: var(--primary);
  --amplify-secondary-shade: var(--primary);
  --amplify-tertiary-color: var(--ternary);
  --amplify-tertiary-contrast: var(--white);
  --amplify-tertiary-tint: var(--ternary);
  --amplify-tertiary-shade: var(--ternary);
  --amplify-grey: var(--grey);
  --amplify-light-grey: var(--light-grey);
  --amplify-white: var(--white);
  --amplify-red: var(--danger);
}

body {
  font-family: "Arial", "Helvetica", sans-serif;
  font-size: rem($font-size-main);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.no-scroll {
    overflow: hidden;
  }
}

.App {
  display: flex;
  flex-flow: row nowrap;

  main {
    position: relative;
    flex: 1;
  }
}
