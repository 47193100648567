@import "../../libs/styles/web/main";

.info-badge {
  --badge-color: #{$secondary};
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-right: $space-20;
  border: 1px solid $lighter-primary;
  border-radius: $border-radius;
  box-sizing: content-box;;

  .icon-container {
    height: $space-40;
    width: $space-40;
    svg {
      height: $space-40;
      width: $space-40;

      fill: var(--badge-color);
    }
  }

  .title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: $space-05 0;
    color: var(--badge-color);
    font-size: $font-size-small;
    white-space: nowrap;

    .title {
      color: inherit;
      font-weight: bold;
    }
  }
}
