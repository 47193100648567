@import "node_modules/bootstrap/scss/functions";

/*
  Override bootstrap variables
 */
$font-family-monospace:       Menlo, "Courier New", monospace;
$font-family-base: 'Arial', 'Helvetica', sans-serif;
$font-weight-base: normal;
$line-height-base: 1.2;

$grid-gutter-width: 20px;

$primary:         #0c89bc;
$primary-darker:  #096d96;

$dusty-primary:   #81a4ca;
$light-primary:   #87c0d8;
$dustier-primary: #b3c8e0;
$lighter-primary: #d9e4ef;
$ice-primary:     #ecf1f7;

$secondary:       #0b4a98;
$secondary-darker: #073874;
$ternary:         #144D93;
$success:         #70b900;
$warning:         #ff9a00;
$warning-darker:  #CC7B00;
$danger:          #ff2626;
$danger-darker:   #E52222;
$highlight:       #ee00ff;

$grey:            #818181;
$grey:            #818181;
$light-grey:      #b7b7b7;
$lighter-grey:    #e5e5e5;
$pale-grey:       #f2f2f2;
$white-grey:      #f5f8fb;
$darker-grey:     #0D0D0D;

$black:           #000000;
$white:           #ffffff;

@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
