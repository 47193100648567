@import '../../../../node_modules/react-quill/dist/quill.snow.css';
@import "../../../libs/styles/web/main";

.ql-toolbar {
    margin-bottom: rem(2px);

    &.ql-snow {
        border: none;
        border-radius: $border-radius $border-radius 0 0;
        background-color: rgba($secondary, 0.2);

        .ql-stroke {
            stroke: $secondary;
        }

        .ql-fill {
            fill: $secondary;
        }

        button,
        .ql-picker-label {
            color: $secondary;
            border-radius: $border-radius;

            &:hover {
                color: $secondary;
                background-color: rgba($secondary, 0.2);
            }

            &:focus {
                outline: none;
                color: $secondary;
                background-color: rgba($secondary, 0.2);
            }
        }

        button {
            display: flex;
            justify-content: center;
            width: $space-30;
            height: $space-30;
        }

        .ql-picker {
            &.ql-expanded {
                .ql-picker-label {
                    border-color: transparent;
                }
            }
        }
    }
}

.ql-snow {
    .ql-picker {
        &.ql-expanded {
            .ql-picker-label {
                .ql-stroke {
                    stroke: $secondary;
                }
            }
        }
    }
}

.ql-container {
    margin-bottom: $space-10;

    &.ql-snow {
        border: none;
    }
}

.ql-editor {
    background-color: $ice-primary;
    border-radius: 0 0 $border-radius $border-radius;
}
