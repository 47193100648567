$space-05: rem(5px);
$space-10: rem(10px);
$space-15: rem(15px);
$space-20: rem(20px);
$space-25: rem(25px);
$space-30: rem(30px);
$space-35: rem(35px);
$space-40: rem(40px);
$space-45: rem(45px);
$space-50: rem(50px);
$space-55: rem(55px);
$space-60: rem(60px);
$space-65: rem(65px);
$space-70: rem(70px);
$space-75: rem(75px);
$space-80: rem(80px);
$space-85: rem(85px);
$space-90: rem(90px);
$space-95: rem(95px);
$space-100: rem(100px);
$space-110: rem(110px);
$space-120: rem(120px);

$base-border: rem(2px);
$border-radius: rem(5px);
$border-large-radius: rem(15px);
$base-box-shadow: 0 $space-10 $space-20 rgba(0, 0, 0, 0.15);
$base-modal-box-shadow: 0px $space-20 $space-80 rgba(0, 0, 0, 0.15);

$font-size-small: rem(12px);
$font-size-main: rem(14px);
$font-size-highlight: rem(16px);
$font-size-title: rem(24px);

$button-height: rem(30px);

// # specifics
$font-size-small-button: $font-size-main;

$button-height-sm: $button-height;
$button-border-radius: $border-radius;

$tab-width: rem(175px);
