@import "../../libs/styles/web/main";

@keyframes delayOverflow {
  0% {
    overflow: hidden;
  }
  99% {
    overflow: hidden;
  }
  100% {
    overflow: visible;
  }
}

.properties-filter {
  .filter-container {
    padding-top: $space-30;
  }
}

.filter {
  padding: $space-40 0 $space-20 0;
}

.collapsable-container {
  position: relative;
  z-index: 2;

  .toggle-button {
    padding: $space-30 $space-10 0;

    .btn {
      padding-right: $space-20;
      width: 150px;
    }
  }

  .base-filter {
    padding: $space-30 $space-10 0;
  }

  .visible-filters {
    position: relative;
    z-index: 2;
  }

  .toggle-filters {
    position: relative;
    z-index: 1;
    max-height: 0;
    overflow: hidden;
    display: block;
    transition: 0.3s linear;

    &.expand {
      max-height: rem(900px);
      animation: delayOverflow .1s;
      animation-fill-mode: forwards;
    }
  }
}

.filter-select-collection {
  display: grid;
  grid-template-columns: repeat(3, 1fr);;
  grid-gap: $space-20;
}

.filter-selection-overview {
  position: sticky;
  top: 0;
  background-color: $white;
  z-index: 1;

  display: flex;
  flex-flow: row wrap;
  margin-bottom: 0;

  &.is-visible {
    padding-top: $space-30;
  }

  li {
    margin: 0 $space-10 $space-10 0;
  }
}
