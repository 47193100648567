form {
  width: 100%;

  fieldset {
    margin-bottom: $space-40;
    padding-left: $space-40;
    padding-right: $space-40;

    &.highlight {
      background-color: $ice-primary;
      padding-top: $space-40;
      padding-bottom: $space-40;
      border-radius: $border-radius;

      input[type=search],
      input[type=text],
      input[type=tel],
      input[type=date],
      input[type=number],
      input[type=email],
      textarea {
        background-color: $white;

        &.autofill {
          background-color: transparent;

          &::placeholder {
            background-color: transparent;
            color: var(--secondary);
            font-style: italic;
          }
        }
      }
    }

    &.separator {
      padding-bottom: $space-40;
      border-bottom: 1px solid $lighter-primary;
    }

    .group-title {
      font-size: $font-size-title;
      font-weight: bold;
      margin-bottom: $space-15;
    }

    label {
      width: 100%;
      text-align: right;
      margin-bottom: 0;
      white-space: nowrap;
    }

    .input-wrapper {
      margin-bottom: $space-10;
      align-items: center;

      .field-array-labels {
        white-space: nowrap;
        text-align: right;
        display: block;
      }

      .field-array-input-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: $space-10;
      }
    }
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    button {
      &:first-of-type {
        margin-right: $space-10;
      }
    }
  }
}

input {
  &[type=search],
  &[type=tel],
  &[type=email],
  &[type=date],
  &[type=number],
  &[type=text] {
    appearance: none;
    padding-left: $space-10;
    padding-top: $space-10;
    padding-right: $space-10;
    padding-bottom: $space-10;
    height: $space-40;
    color: $black;
    background-color: $ice-primary;
    caret-color: $primary;
    width: 100%;
    height: $space-40;
    line-height: normal;
    border-radius: $border-radius;
    @include inset-border(transparent);

    &[type=number] {
      line-height: 1;
    }

    &:focus {
      outline: none;
    }

    &::placeholder {
      font-style: italic;
      color: var(--dusty-primary) !important;
    }

    &::-webkit-inner-spin-button,
    &::-webkit-clear-button {
      display: none;
    }

    &::-webkit-calendar-picker-indicator {
      opacity: 0;
    }

    &.mark-as-fallback {
      &:not(:focus) {
        color: $primary;
        font-style: italic;
      }
    }

    &[readonly] {
      color: $grey;

      &::placeholder {
        color: $grey;
      }
    }

    &.autofill {
      @include inset-border(var(--lighter-primary));
      background-color: var(--white);
      color: var(--secondary);
      font-style: italic;
  
      &::placeholder {
        background-color: var(--white);
        color: var(--secondary);
        font-style: italic;
      }
    }

    &:not([readonly], .autofill) {
      &::placeholder {
        color: $black;
      }

      &:focus {
        @include input-focus-border($ternary);
        background-color: $lighter-primary;

        &::placeholder {
          font-style: italic;
          color: $dusty-primary;
        }

        &:hover {
          @include no-touch-device-hover() {
            &::placeholder {
              color: $dusty-primary;
            }
          }
        }
      }

      &:hover {
        @include no-touch-device-hover() {
          &::placeholder {
            color: $secondary;
          }

          background-color: $lighter-primary;
        }
      }
    }
  }

  &[type=search]::-webkit-search-decoration,
  &[type=search]::-webkit-search-cancel-button,
  &[type=search]::-webkit-search-results-button,
  &[type=search]::-webkit-search-results-decoration {
    -webkit-appearance: none;
    display: none;
  }

  &[type="number"] {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

textarea {
  color: $black;
  background-color: $ice-primary;
  caret-color: $primary;
  width: 100%;
  line-height: normal;
  border-radius: $border-radius;
  padding-left: $space-10;
  padding-top: $space-10;
  padding-right: $space-10;
  padding-bottom: $space-10;
  @include inset-border(transparent);

  &:focus {
    outline: none;
  }

  &:not([readonly]) {
    &::placeholder {
      color: var(--secondary);
      font-style: italic;
    }

    &:focus {
      @include input-focus-border($ternary);
      background-color: $lighter-primary;

      &::placeholder {
        font-style: italic;
        color: $dusty-primary;
      }

      &:hover {
        @include no-touch-device-hover() {
          &::placeholder {
            color: $dusty-primary;
          }
        }
      }
    }

    &:hover {
      @include no-touch-device-hover() {
        &::placeholder {
          color: $secondary;
        }

        background-color: $lighter-primary;
      }
    }
  }
}
