@import "../../../libs/styles/web/main";

.mapbox-address-input {
  display: flex;
  align-items: center;

  .address-wrapper {
    flex: 1;

    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .address-input {
      flex: 1;
    }

    &.is-focused {
      .search-suggestions {
        opacity: 1;
        visibility: visible;
      }
    }

    .search-suggestions {
      position: absolute;
      top: calc(100% + #{$space-05});
      width: auto;
      min-width: 100%;
      z-index: 1;
      background-color: $white;
      filter: drop-shadow($base-box-shadow);
      border-radius: $border-radius;
      opacity: 0;
      visibility: hidden;
      overflow: hidden;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      max-height: rem(275px);
      will-change: filter, box-shadow;

      li {
        line-height: 1;

        &.empty-result {
          padding: $space-20 $space-85;
          text-align: center;
          font-style: italic;
          color: $grey;
          white-space: nowrap;
        }

        &.is-result {
          button {
            text-align: left;
            padding: $space-15 $space-10;
            background-color: $white;
            width: 100%;
            white-space: nowrap;
            outline: none;

            &:hover, &:focus {
              @include no-touch-device-hover() {
                background-color: $lighter-primary;
              }
            }
          }

          &.active {
            button {
              @include no-touch-device-hover() {
                background-color: $lighter-primary;
              }
            }
          }
        }
      }
    }
  }
}
