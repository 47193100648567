@import "../../libs/styles/web/main";

.controls-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  > div {
    flex: 1;

    &.full,
    &.middle {
      @extend .text-center;
    }
    &.left {
      @extend .text-left;
    }
    &.right {
      @extend .text-right;

      display: flex;
      justify-content: flex-end;

      > * {
        &:not(:last-child) {
          margin-right: $space-10;
        }
      }
    }
  }
}
