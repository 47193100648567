@keyframes spin-animation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(359deg);
  }
}

@mixin spin-animation {
  animation: spin-animation 1s infinite linear;
}

@mixin no-touch-device-hover {
  @media (hover: hover) {
    @content;
  }
}

@mixin inset-border($color, $size: 1px) {
  box-shadow: inset 0 0 0 $size $color;
}

@mixin input-focus-border($color) {
  box-shadow: inset 0 0 0 $base-border $color;
}
