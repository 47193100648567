@import "../../../libs/styles/web/main";

.range-input-wrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  .dropdown-content-wrapper & {
    flex-flow: row nowrap;
  }

  .range-input-title {
    text-transform: uppercase;
    width: 100%;
    margin-bottom: $space-15;
    font-weight: bolder;
  }

  .separator-label {
    color: $grey;
    display: inline-block;
    padding: 0 $space-10;
  }

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }

  .input-wrapper {
    margin-bottom: 1.5rem;
  }

  .separator-label {
    margin-bottom: 1.5rem;
  }

  .range-input {
    @include inset-border($lighter-grey);
    text-align: center;
    background-color: $white;
    flex: 1;

    .dropdown-content-wrapper & {
      width: auto;
    }

    &.small {
      max-width: rem(120px);
    }

    &.large {
      max-width: rem(130px);
    }

    &:hover {
      @include no-touch-device-hover() {
        background-color: $ice-primary;
      }
    }

    &:focus {
      @include input-focus-border($ternary);
      background-color: $ice-primary;
      outline: none;
    }
  }
}
