@import "../../../libs/styles/web/main";

$borderColor: transparent;

.dialog {
  position: absolute;
  top: calc(50% - #{rem(40px)});
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: $white;
  border-radius: $border-radius;
  filter: drop-shadow($base-modal-box-shadow);
  max-width: rem(500px);
  width: 100%;

  &-wrapper {
    padding: $space-20;
  }

  &-title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: $space-20;

    h2 {
      font-weight: bold;
      font-size: rem(20px);
      margin: 0;
    }

    .btn.close {
      position: absolute;
      right: $space-20;
    }

    @include inset-border($borderColor);
  }

  &-message {
    font-size: rem(16px);
    padding: $space-20;
    @include inset-border($borderColor);
  }

  &-controls {
    @include inset-border($borderColor);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: rem(14px);
    padding-top: $space-20;

    & .btn {
      margin-right: $space-10;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}
