@import "../../../libs/styles/web/main";

.base-input {
  position: relative;
  display: flex;

  input {
    flex: 1;
    max-width: 100%;
  }

  svg {
    width: $space-40;
    height: $space-40;

    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }

  .icon-container {
    position: absolute;
    right: 0;
    bottom: 8px;
    // transform: translateY(-50%);
    pointer-events: none;

    width: $space-40;
    height: $space-40;

    svg {
      width: $space-40;
      height: $space-40;

      &.loading {
        width: $space-20;
        height: $space-20;
        margin-right: rem(12px);
      }
    }
  }
}
