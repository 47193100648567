@import "../../libs/styles/web/main";

a.link {
  &.download {
    display: inline-flex;
    align-items: center;
    height: $button-height-sm;
    color: $publity-blue;
    text-decoration: none;
    padding-right: $space-10;
    border-radius: $button-border-radius;

    &:hover {
      @include no-touch-device-hover() {
        background-color: $button-hover-background;
      }
    }

    &:active {
      background-color: $button-active-background;
    }
  }
}
