@import "../../../libs/styles/web/main";

.checkbox-select {
  appearance: none;
  display: none;

  &:checked {
    & + .checkbox-select-label {
      .checkbox-select-icon-wrapper {
        background-color: $secondary;
        border-color: $secondary;

        svg[color=transparent] {
          fill: $white;
        }
      }
    }
  }
}

.checkbox-select-label {
  display: flex;
  align-items: center;
  font-size: $font-size-highlight;
  white-space: nowrap;

  &:not(.readonly) {
    &:hover {
      cursor: pointer;

      @include no-touch-device-hover() {
        .checkbox-select-icon-wrapper {
          background-color: $ice-primary;
        }
      }
    }
  }

  .checkbox-select-icon-wrapper {
    content: '';
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(20px);
    height: rem(20px);
    border-radius: $border-radius;
    background-color: transparent;
    border: 1px solid $grey;
    margin-right: $space-10;
    text-align: center;
    flex-shrink: 0;

    svg {
      width: rem(14px);
      height: rem(11px);
    }
  }
}

// #############
// Radio
// #############


.radio-select {
  appearance: none;
  display: none;

  &:checked {
    & + .radio-select-label {
      .radio-select-icon-wrapper {
        svg[color=transparent] {
          fill: $secondary;
        }

        &.red {
          svg[color=transparent] {
            fill: $danger;
          }
        }

        &.orange {
          svg[color=transparent] {
            fill: $warning;
          }
        }

        &.green {
          svg[color=transparent] {
            fill: $success;
          }
        }
      }
    }
  }
}

.radio-select-label {
  display: flex;
  align-items: center;
  font-size: $font-size-main;
  text-align: left;
  white-space: nowrap;

  &:not(.readonly) {
    &:hover {
      cursor: pointer;

      .radio-select-icon-wrapper {
        background-color: $ice-primary;
      }
    }
  }

  .radio-select-icon-wrapper {
    content: '';
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(20px);
    height: rem(20px);
    border-radius: 50%;
    background-color: transparent;
    border: 1px solid $grey;
    margin-right: $space-10;
    text-align: center;
    flex-shrink: 0;

    svg {
      width: rem(14px);
      height: rem(14px);
    }
  }
}
