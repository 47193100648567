@import "../../libs/styles/web/main";

.action-teaser {
  position: relative;
  border-radius: $border-radius;
  background-color: $ice-primary;
  padding: $space-95 $space-50 rem(140px);

  .action-teaser-icon {
    display: inline-block;
    border-radius: 50%;
    background-color: $lighter-primary;
    margin-bottom: $space-40;

    svg {
      width: rem(140px);
      height: rem(140px);
    }
  }

  .action-teaser-title {
    font-size: rem(20px);
    color: $secondary;
    font-weight: 600;
  }

  .action-teaser-text {
    font-size: $font-size-main;
    line-height: 1.5;
  }

  .action-teaser-trigger-label {
    position: absolute;
    bottom: $space-30;
    right: $space-30;
    color: $secondary;
    display: flex;
    align-items: center;

    svg {
      width: $space-40;
      height: $space-40;
    }
  }

  &:hover {
    @include no-touch-device-hover() {
      background-color: $lighter-primary;

      .action-teaser-icon {
        background-color: $dustier-primary;
      }
    }
  }

  &:focus {
    outline: none;
  }
}
