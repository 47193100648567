@import "../../../libs/styles/web/main";

.modal {
  position: absolute;
  top: calc((var(--vh, 1vh) * 100) / 2);
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100vw - #{$space-100});
  max-width: rem(1000px);
  max-height: calc((var(--vh, 1vh) * 100) - #{$space-100});
  background-color: $white;
  box-shadow: 0 $space-20 $space-80 0 rgba($black, 0.2);
  border-radius: $border-radius;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  .modal-header {
    position: relative;
    padding: $space-20;

    width: 100%;
    display: flex;

    .left-modal-header {
      display: flex;
      flex: 1;
      justify-content: flex-start;
    }

    .right-modal-header {
      display: flex;
      flex: 1;
      justify-content: flex-end;
    }

    .modal-back-button {
      display: flex;
      align-items: center;
      left: $space-30;
      color: $secondary;
      padding-right: $space-10;
      border-radius: $border-radius;

      svg {
        width: $space-30;
        height: $space-30;
      }

      &:focus {
        outline: none;
      }

      &:hover {
        @include no-touch-device-hover() {
          background-color: $ice-primary;
        }
      }
    }

    .modal-title {
      font-weight: 600;
      margin-bottom: 0;
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
    }


    .btn {
      right: $space-20;
      border-radius: $border-radius;

      svg {
        width: $space-40;
        height: $space-40;
      }

      &:hover {
        @include no-touch-device-hover() {
          background-color: $ice-primary;
        }
      }
    }
  }

  .modal-body {
    display: grid;
    grid-gap: $space-10;
    padding: 0 $space-10 $space-10;

    &.full {
      grid-template-columns: 1fr;
    }

    &.two-column {
      grid-template-columns: 1fr 1fr;
    }

    form {
      padding: $space-35 $space-10, $space-10, $space-10;

      fieldset {
        padding-right: $space-110;
      }

      .button-container {
        padding-top: $space-90;
      }
    }
  }
}
