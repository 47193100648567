@import "../../libs/styles/web/main";

$table-panel-base-font-size: rem(20px);
$arrow-dimension: rem(5px);

@keyframes loadingCell {
  0% {
    background-color: $lighter-primary;
  }
  50% {
    background-color: $ice-primary;
  }
  100% {
    background-color: $lighter-primary;
  }
}

.table-panel {
  display: flex;
  align-items: flex-end;
  padding: $space-50 0 $space-70 0;

  position: sticky;
  background-color: $white;
  z-index: 1;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: $space-30;
    height: $base-border;
    width: 100%;
    display: block;
    background-color: $lighter-grey;
  }

  li {
    padding: 0 $space-40 0 $space-20;
    font-size: $table-panel-base-font-size;
    border-left: $base-border solid $lighter-grey;

    &:first-child {
      border-left: none;
      padding: 0 $space-40 0 0;
    }

    &.right {
      font-size: $font-size-small-button;
      margin-left: auto;
      border-left: none;
      padding: 0;
    }

    &.table-title {
      font-size: rem($font-size-title);
      font-weight: bolder;
    }
  }
}

.table {
  min-width: 100%;
  border-collapse: separate;
  border-spacing: 0 $base-border;
  table-layout: fixed;
  user-select: none;

  &.scrollable {
    display: block;
    width: calc(100vw - var(--scrollbar-width) - var(--app-menu-width) - 20px); //20px from col/row system
    overflow: auto;
  }

  thead, tfoot {
    th {
      position: relative; // fallback
      position: sticky;
      background-color: $white;
      min-width: 120px;
      z-index: 1;

      &:after {
        content: "";
        height: rem(2px);
        display: block;
        background-color: $lighter-grey;
        position: absolute;
        bottom: 0;
        width: calc(100% - #{$space-10})
      }

      &:last-child {
        &:after {
          width: 100%;
        }
      }

      &.is-sorted {
        &:after {
          background-color: $secondary;
        }
      }

      button, .label {
        padding: $space-10 $space-20 $space-10 $space-10;
        display: inline-block;
        text-align: left;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        font-weight: 600;
        border: none;
        background-color: transparent;

        &:focus,
        &:active {
          outline: none;
        }
      }

      .sort {
        display: inline-block;
      }

      .sort-asc,
      .sort-desc {
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border: $arrow-dimension solid transparent;
        pointer-events: none;

        &.right {
          left: $space-10;
        }

        &.left {
          right: $space-20;
        }
      }

      .sort-asc {
        top: 50%;
        transform: translateY(-85%);
      }

      .sort-desc {
        top: 50%;
        transform: translateY(25%);
      }

      &:hover {
        @include no-touch-device-hover() {
          button {
            color: $secondary;
          }

          .sort-asc {
            border-bottom-color: $dustier-primary;
          }

          .sort-desc {
            border-top-color: $dustier-primary;
          }
        }
      }

      /*&:last-child {
        button {
          &:after {
            width: 100%;
          }
        }
      }*/

      &.is-sorted {
        button {
          color: $secondary;
        }

        .sort-asc {
          border-bottom-color: $dustier-primary;
        }

        .sort-desc {
          border-top-color: $dustier-primary;
        }

        &.sort-by-asc {
          .sort-asc {
            border-bottom-color: $secondary;
          }
        }

        &.sort-by-desc {
          .sort-desc {
            border-top-color: $secondary;
          }
        }
      }
    }
  }

  tfoot {

    &.loading {
      opacity: 0;

      &:not(:empty):before, &:not(:empty):after {
height: 0;
      }
      }

    &:not(:empty):before, &:not(:empty):after {
      content: "";
      height: rem(8px);
      display: block;
      width: 100%;
    }

    td {
      position: relative;

      &:after {
        content: "";
        height: rem(2px);
        display: block;
        background-color: $lighter-grey;
        position: absolute;
        top: 0;
        width: calc(100% - #{$space-10})
      }

      &:last-child {
        &:after {
          width: 100%
        }
      }

      &.is-sorted {
        &:after {
          background-color: $secondary;
        }
      }
    }
  }

  caption.loading-cell {
    caption-side: bottom;
    padding-top: 0;
    padding-bottom: 0;
    min-height: 1px;

    > span {
      background-color: $lighter-primary;
      position: relative;
      display: inline-block;
      width: 100%;
      padding: 0;
      height: rem(39px);
      border-radius: $border-radius;
      animation: loadingCell 1s linear infinite;
      vertical-align: middle;


    .icon-container {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
  }


  tbody {
    &:not(:empty):before {
      content: "";
      height: rem(8px);
      display: block;
      width: 100%;
    }

    tr {
      cursor: pointer;

      td {
        position: relative;
        text-align: left;
        min-width: 120px;
        vertical-align: top;

        > span {
          display: inline-block;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &:first-child {
          border-radius: $border-radius 0 0 $border-radius;
        }

        &:last-child {
          border-radius: 0 $border-radius $border-radius 0;
        }

        &.no-data-cell {
          text-align: center;
          border-radius: $border-radius;
        }
      }

      &.is-sorted {
        color: $secondary;
      }
    }
  }


  &.striped {
    tbody {
      tr {

        td {
          padding: $space-10;
          background-color: $white-grey;
        }

        &:hover {
          @include no-touch-device-hover() {
            td {
              background-color: $lighter-primary;
            }
          }
        }

        &:nth-child(odd) {
          td {
            background-color: $ice-primary;
          }

          &:hover {
            @include no-touch-device-hover() {
              td {
                background-color: $lighter-primary;
              }
            }
          }
        }
      }
    }
  }
}
