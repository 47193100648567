* {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

ol,
ul {
  list-style: none;
}
